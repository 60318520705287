.musikerPage {
    display: flex;
    justify-content: center;
  }
  
  .musikerContainer {
    max-width: 800px;
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .musikerContainer h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .register {
    margin-bottom: 40px;
    text-align: center;
  }
  
  .register h2 {
    margin-bottom: 10px;
  }
  
  .registerImage {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    .musikerContainer {
      max-width: 80%;
    }
  }