.profilePage {
 display: flex;
 flex-direction: column;
 align-items: center;
 font-family: Arial, sans-serif;
 color: #333;
}

.profilePage h2 {
 margin-bottom: 20px;
}

.profileInfo {
 width: 100%;
 max-width: 400px;
 background: white;
 padding: 20px;
 border-radius: 8px;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.inputGroup {
 margin-bottom: 15px;
}

.inputGroup label {
 display: block;
 margin-bottom: 5px;
 font-weight: bold;
}

.inputGroup input {
 width: calc(100% - 22px);
 padding: 10px;
 border: 1px solid #ddd;
 border-radius: 4px;
}

.updateBtn {
 width: 100%;
 padding: 10px;
 background-color: #007bff;
 color: white;
 border: none;
 border-radius: 4px;
 cursor: pointer;
 font-size: 16px;
}

.updateBtn:hover {
 background-color: #0056b3;
}

.message {
 color: green;
 margin-bottom: 15px;
}
