.createPostPage {
 width: 100%;
 height: auto;
 display: grid;
 place-items: center;
margin-bottom: 20px;
}

.cpContainer {
 width: 100%;
 max-width: 500px;
 height: auto;
 padding: 20px;
 border-radius: 12px;
 color: white;
 display: flex;
 flex-direction: column;
 background-color: #01001E;
}

.cpContainer h1 {
 text-align: center;
}

.inputGp label {
 margin-top: 2vh;
}

.cpContainer label {
 font-size: 25px;
}

.inputGp {
 margin-top: 30px;
 display: flex;
 flex-direction: column;
}

.inputGp input,
.inputGp textarea {
 font-size: 18px;
 border: none;
 border-radius: 2px;
 padding: 5px;
}

.inputGp input {
 height: 40px;
}

.inputGp textarea {
 height: 150px;
}

.cpContainer button {
 margin-top: 20px;
 height: 40px;
 border: none;
 border-radius: 5px;
 cursor: pointer;
 font-size: 18px;
}

input,
textarea {
 margin-top: 5px;
}

.outerbar {
 width: 100%;
 color: white;
 margin-top: 10px;
}

.innerbar {
 height: 30px;
 background-color: #4caf50;
 text-align: center;
 color: rgb(255, 255, 255);
}

.uploadedImages {
 display: flex;
 flex-wrap: wrap;
 gap: 10px;
 margin-top: 10px;
}

.image-item {
 position: relative;
 display: inline-block;
}

.delete-btn {
 position: absolute;
 top: 5px;
 right: 5px;
 background-color: red;
 color: white;
 border: none;
 border-radius: 50%;
 cursor: pointer;
 width: 20px;
 height: 20px;
 text-align: center;
 line-height: 20px;
}

/* Stilisiert die Quill Toolbar */
.ql-toolbar.ql-snow {
 display: flex;
 flex-wrap: wrap;
 align-items: center;
 justify-content: flex-start;
 background-color: white;
}

.ql-container.ql-snow {
 border: 1px solid #ccc;
 border-top: none;
 color: white;
}

.ql-editor {
 background-color: white;
 color: black;
}
.ql-snow .ql-picker-label {
 display: flex;
}