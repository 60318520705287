


.homeContainer {
  text-align: center;
  width: 60%;
}


.latestPost, .nextEvent {
  background-color: #f9f9f9;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.latestPost h2, .nextEvent h2 {
  margin-bottom: 10px;
}

.postPreview img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.postTextPreview {
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
  text-align: left;
}

.postTextPreview a {
  display: inline-block;
  margin-top: 5px;
  color: blue;
  text-decoration: underline;
}
@media (max-width: 768px) { /* für mobile Geräte */
  .homeContainer {
    width: 90%;
  }
  .latestPost, .nextEvent {
    background-color: #f9f9f9;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
}
