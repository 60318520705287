.calendarPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
  }
  
  .calendarPage h1 {
    margin-bottom: 20px;
    text-align: center; /* Zentriert den Titel für besseren mobilen Look */
  }
  
  .react-calendar {
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%; /* Passt die Kalendergröße an den Bildschirm an */
    max-width: 400px; /* Begrenzung für große Bildschirme */
  }
  
  .react-calendar__tile--active {
    background-color: #4caf50 !important;
    color: white !important;
  }
  
  .eventDot {
    height: 10px;
    width: 10px;
    background-color: #4caf50;
    border-radius: 50%;
    display: inline-block;
    margin: 0 auto;
  }
  
  .eventDetails {
    margin-top: 20px;
    background-color: #333;
    padding: 10px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
  }
  
  .eventDetails h2 {
    margin-top: 0;
    font-size: 1.2em; /* Etwas kleinere Schriftgröße für Mobilgeräte */
  }
  
  .toggleButton {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .eventsTable {
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
    background-color: white;
    color: black;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .eventsTable table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9em; /* Kleinere Schrift für Mobilgeräte */
  }
  
  .eventsTable th, .eventsTable td {
    padding: 8px; /* Reduziert Padding für Mobilgeräte */
    border: 1px solid #ddd;
  }
  
  .eventsTable th {
    background-color: #b81a49;
    color: white;
    text-align: left;
  }
  
  .outerbar {
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .innerbar {
    height: 20px;
    background-color: #4caf50;
    border-radius: 5px;
    text-align: center;
    color: white;
    line-height: 20px;
  }
  
  /* Responsive Design für Mobilgeräte */
  @media (max-width: 768px) {
    .cpContainer{
        width: 80%;
    }
    .calendarPage {

      padding: 0 10px; /* Füge etwas Padding auf Mobilgeräten hinzu */
    }
  
    .react-calendar {
      width: 100%; /* Volle Breite auf Mobilgeräten */
      max-width: 100%; /* Keine Begrenzung auf Mobilgeräten */
    }
  
    .eventDetails {
      width: 100%; /* Volle Breite für Events auf Mobilgeräten */
      max-width: 100%;
    }
  
    .eventsTable {
      width: 100%; /* Tabelle auf volle Breite für Mobilgeräte */
      max-width: 100%;
      font-size: 0.8em; /* Kleinere Schriftgröße für bessere Lesbarkeit */
    }
  
    .eventsTable th, .eventsTable td {
      padding: 5px; /* Reduziert Padding auf Mobilgeräten */
    }
  
    .toggleButton {
      width: 100%; /* Vollbildbreite für Buttons */
      font-size: 14px; /* Kleinere Schriftgröße für Mobilgeräte */
    }
  }
  