.aboutPage {
    display: flex;
    justify-content: center;
  }
  
  .aboutContainer {
    max-width: 800px;
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    align-items: center;
    display: flex;
    flex-direction: column;
    
  }

  
  .aboutContainer h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .aboutContainer p {
    margin-bottom: 20px;
    line-height: 1.6;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .aboutImage {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    .aboutContainer {
      width: 80%;
    }
  }
  