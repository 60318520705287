.probenPage {
    width: 100%;
    max-width: 800px;

    padding: 20px;
    background-color: white;
    border-radius: 8px;
    color: black;
  }
  
  .probenPage h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .probenPage p {
    margin-bottom: 10px;
    line-height: 1.6;
  }
  @media (max-width: 768px) {
    .probenPage {
      width: 80%;
    }
  }

  