.anfahrt-container {
  max-width: 800px;

  padding: 0 20px 20px 20px;
  background-color: #fff;
  color: #333;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.anfahrt-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.anfahrt-container p {
  margin: 10px 0;
}

.map-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .anfahrt-container {
    padding: 10px;
    width: 80%;
  }

  .anfahrt-container h1 {
    font-size: 1.2em;
  }
}
.anfahrt-container {
  padding: 20px;
  text-align: center;
}

.map-container {
  margin-top: 20px;
}

.consent-container {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.consent-container p {
  margin-bottom: 20px;
}

.consent-container button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.consent-container button:hover {
  background-color: #0056b3;
}