.impressumContainer {
 line-height: 1.6;
 font-family: Arial, sans-serif;
 color: #333;
 width: 90%;
 max-width: 800px;
 background-color: white;
 align-items: center;
 align-self: center;
 border-radius: 15px;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
 margin: 20px;
 padding: 20px;
}


.impressumContainer h1 {
 text-align: center;
 margin-bottom: 20px;
 font-size: 1.8em;

}

.impressumContainer section {
 margin-bottom: 20px;
}

.impressumContainer h2 {
 font-size: 1.5em;
 margin-bottom: 10px;
}

.impressumContainer h3 {
 font-size: 1.2em;
 margin-bottom: 10px;
}

.impressumContainer p {
 margin-bottom: 10px;
}

.impressumContainer ul {
 list-style-type: disc;
 margin-left: 20px;
}

.impressumContainer a {
 color: #0066cc;
 text-decoration: none;
 font-size: medium;
}

.impressumContainer a:hover {
 text-decoration: underline;
}

@media (max-width: 768px) {

  .impressumContainer {
    padding: 15px;
    width: 80%;

    border-radius: 10px;
  }

  .impressumContainer h1 {
    font-size: 1.5em;
  }

  .impressumContainer h2 {
    font-size: 1.3em;
  }

  .impressumContainer h3 {
    font-size: 1.1em;
  }

  .impressumContainer p {
    font-size: 0.9em;
  }

  .impressumContainer a {
    font-size: small;
  }
}