.imageUpload{
 width: 50%;
 height: auto;
 max-height: 600px;
 background-color: rgb(250, 250, 250);
 box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 margin: 20px;
 padding: 20px;
 border-radius: 15px;
}

.createPostPage {
 width: 100%;
 height: calc(100vh - 80px);
 display: grid;
 place-items: center;
}

.cpContainer {
 width: 500px;
 height: auto;
 padding: 20px;
 background-color: #01001E;
 border-radius: 12px;
 color: white;
 display: flex;
 flex-direction: column;
}

.cpContainer h1 {
 text-align: center;
}

.cpContainer label {
 font-size: 25px;
}
.cpContainer .inputGp {
 margin-top: 30px;
 display: flex;
 flex-direction: column;
}

.inputGp input,
.inputGp textarea {
 font-size: 18px;
 border: none;
 border-radius: 2px;
 padding: 5px;
}

.inputGp input {
 height: 40px;
}
.inputGp textarea {
 height: 150px;
}

.cpContainer button {
 margin-top: 20px;
 height: 40px;
 border: none;
 border-radius: 5px;
 cursor: pointer;
 font-size: 18px;
}
input,
textarea {
 margin-top: 5px;
}

.loginPage {
 width: 100vw;
 height: calc(100vh - 80px);
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
}

.loginPage p {
 font-size: 30px;
}

.homePage {
 width: 100%;
 min-height: calc(100vh - 80px);
 height: auto;
 display: flex;
 flex-direction: column;
 align-items: center;
}

.homePage .post {
 width: 50%;
 background-color: rgb(250, 250, 250);
 box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 margin: 20px;
 padding: 20px;
 border-radius: 15px;
}

.post .postHeader {
 display: flex;
 justify-content: center;
 width: 100%;
}

.postHeader .title {
 flex: 50%;
}
.postHeader .deletePost {
 flex: 50%;
 display: flex;
 flex-direction: column;
 align-items: flex-end;
}

.deletePost button {
 border: none;

 background: none;
 font-size: 30px;
 cursor: pointer;
}

.post .postTextContainer {
 word-wrap: break-word;
 height: auto;
 width: 100%;
 overflow: hidden;
 overflow-y: auto;
}

/* Ovlerlay in News*/
.postImage {
 cursor: pointer;
 transition: transform 0.2s;
 width: 20%;
 padding: 5px;
}

.postImage:hover {
 transform: scale(1.05);
}

.overlay {
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: rgba(0, 0, 0, 0.8);
 display: flex;
 justify-content: center;
 align-items: center;
 z-index: 1000;
}

.overlayImage {
 max-width: 90%;
 max-height: 90%;
}

.close, .prev, .next {
 color: white;
 font-size: 40px;
 font-weight: bold;
 position: absolute;
 cursor: pointer;
}

.close {
 top: 20px;
 right: 20px;
}

.prev, .next {
 top: 50%;
 transform: translateY(-50%);
}

.prev {
 left: 20px;
}

.next {
 right: 20px;
}

/* Optimierung für Mobilgeräte */
@media (max-width: 768px) {
    .homePage .post {
      width: 80%; /* 90% der Breite */
      margin: 10px; /* 10px Margin */
      padding: 10px; /* 10px Padding */
    }
  
    .imageUpload {
      width: 90%; /* 90% der Breite für Bilder */
      margin: 10px; /* 10px Margin */
      padding: 10px; /* 10px Padding */
    }
  
    .cpContainer {
      width: 60%; /* 90% der Breite */
      margin: 10px; /* 10px Margin */
      padding: 10px; /* 10px Padding */
    }
  }
  