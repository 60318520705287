/* General Styles */
body {
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  background-image: url("Images/background.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #01001e;
  overflow: hidden; /* Verhindert die Standard-Scrollbar */

}

/* Custom Scrollbar */
html {
  overflow: hidden;
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #01001e; /* Hintergrundfarbe der Scrollbar */
}

body::-webkit-scrollbar-thumb {
  background-color: #555; /* Farbe des Scrollbalkens */
  border-radius: 6px; /* Abgerundete Ecken */
  border: 3px solid #01001e; /* Abstand und Hintergrundfarbe */
}

/* Scroll Container */
.scroll-container {
  height: 100vh;
  overflow-y: scroll;
  scrollbar-width: thin; /* Für Firefox */
  scrollbar-color: #555 #01001e; /* Für Firefox */
}

/* Navbar Styles */
.navbar {
  margin: 0;
  width: 98%;
  padding: 1%;
  background-color: #01001e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  text-decoration: none;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.menu-toggle {
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: none;
}

.navbar-links {
  display: flex;
  gap: 15px;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  padding: 10px;
}

.navbar-links a:hover {
  background-color: #55555583;
  border-radius: 5px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a,
.dropdown-content .logout-btn {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background: none;
  border: none;
  text-align: left;
}

.dropdown-content a:hover,
.dropdown-content .logout-btn:hover {
  background-color: #cdcfd0;
}

.dropdown:hover .dropdown-content {
 display: block;
}

.dropbtn {
  background-color: #01001e;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.profile-dropdown .dropbtn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-dropdown .dropbtn .username {
  margin-top: 5px;
  font-size: 12px;
}

.login-icon,
.home-icon {
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.login-container{
  flex-direction: column;
  align-items: center;
  display: flex;
  text-decoration: none;
  color: white;
}

.logout-btn {
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  text-align: left;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
    width: 100%;
  }

  .menu-toggle {
    display: block;
  }

  .navbar-links.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #01001e;
    width: 100%;
    text-align: center;
  }

  .navbar-links a,
  .navbar-links .logout-btn {
    padding: 1rem;
  }

  .dropdown-content {
    position: relative;
  }
}

/* Content and Footer Styles */
.content {
  flex: 1;
  padding-top: 15vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 74%;
}

.footer {
  width: 100%;
  padding: 20px 0;
  background-color: #01001e;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer p {
  margin: 0;
}

.footer a {
  color: inherit; /* Entfernt die spezifische Linkfarbe */
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline; /* Optional: Link bei Hover unterstreichen */
}
