.satzung-container {
    max-width: 800px;

    padding: 20px;
    background-color: #fff;
    color: #333;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  
  .satzung-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .satzung-container h2 {
    font-size: 1.5em;
    margin-top: 20px;
  }
  
  .satzung-container p {
    margin: 10px 0;
  }
  
  @media (max-width: 768px) {
    .satzung-container {
      padding: 10px;
      width: 80%;
    }
  
    .satzung-container h1 {
      font-size: 1.2em;
    }
  
    .satzung-container h2 {
      font-size: 1.2em;
    }
  }
  