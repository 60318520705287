.loginPage {
 align-items: center;
 height: 100%;
 display: flex;
 flex-direction: column;
 width: auto;

}

.loginForm {
 background: white;
 padding: 20px;
 border-radius: 8px;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 width: 300px;
 text-align: center;
}

.inputGroup {
 margin-bottom: 15px;
 text-align: left;
}

.inputGroup label {
 display: block;
 margin-bottom: 5px;
 font-weight: bold;
}

.inputGroup input {
 width: calc(100% - 22px);
 padding: 10px;
 border: 1px solid #ddd;
 border-radius: 4px;
}

.loginBtn {
 width: 100%;
 padding: 10px;
 background-color: #007bff;
 color: white;
 border: none;
 border-radius: 4px;
 cursor: pointer;
 font-size: 16px;
}

.loginBtn:hover {
 background-color: #0056b3;
}

.error {
 color: red;
 margin-bottom: 15px;
}
