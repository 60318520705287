/* Newsletter.css */
.newsletter-container {
 width: 100%;
 max-width: 500px;
 margin: 0 auto;
 padding: 20px;
 background-color: #fff;
 border-radius: 8px;
 box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 text-align: center;
}

.newsletter-container h2 {
 margin-bottom: 20px;
}

.newsletter-container form {
 display: flex;
 flex-direction: column;
 align-items: center;
}

.newsletter-container input {
 width: 100%;
 padding: 10px;
 margin-bottom: 15px;
 border: 1px solid #ddd;
 border-radius: 4px;
}

.newsletter-container button {
 padding: 10px 20px;
 background-color: #007bff;
 color: white;
 border: none;
 border-radius: 4px;
 cursor: pointer;
}

.newsletter-container button:hover {
 background-color: #0056b3;
}

.newsletter-container p {
 margin-top: 15px;
 color: green;
}
