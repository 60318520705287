/* Seite mit halbtransparentem Hintergrund für die Überschrift */
.calendarHeader {
    background-color: rgba(0, 0, 0, 0.6); /* Halbtransparenter Hintergrund */
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    margin-bottom: 20px;
  }
  
  /* Kartencontainer */
  .eventCardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
  }
  
  /* Einzelne Karten für Events */
  .eventCard {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 10px;
    width: 80%;
    max-width: 600px;
  }
  
  .eventCard:hover {
   /* transform: translateY(-5px);*/
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Linke Seite - Datum */
  .eventCardDate {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    font-weight: bold;
  }
  
  .eventDay {
    font-size: 48px; /* Große Zahl für den Tag */
    color: black;
  }
  
  .eventMonth {
    font-size: 16px; /* Kleinere Buchstaben für den Monat */
    color: black;
  }
  
  /* Rechte Seite - Textinhalt */
  .eventCardContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left; /* Links ausgerichteter Text auf großen Bildschirmen */
    flex-grow: 1;
  }
  
  .eventCardHeader h3 {
    margin: 0;
    font-size: 20px;
    color: #01001e;
  }
  
  .eventCardBody p {
    margin: 5px 0;
    color: #333;
    font-size: 16px;
  }
  
  /* Löschen-Button unter dem Text */
  .eventCardActions {
    margin-top: 10px; /* Platz zwischen Text und Button */
  }
  
  .deleteButton {
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%; /* Breiter Button */
  }
  
  .deleteButton:hover {
    background-color: #ff1a1a;
  }
  
  /* Bild auf der rechten Seite */
  .eventCardImage img {
    max-width: 150px;
    height: auto;
    border-radius: 8px;
    margin-left: 20px;
  }
  
  /* Popup-Stil */
  .confirmDeletePopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
  }
  
  .popupContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .popupContent p {
    font-size: 18px;
    margin-bottom: 20px;
    color: black;
  }
 /* Buttons unter dem Textinhalt */
.eventCardActions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
  }
  
  .editButton, .deleteButton {
    background-color: #4CAF50; /* Grün für Bearbeiten */
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .deleteButton {
    background-color: #ff4d4d; /* Rot für Löschen */
  }
  
  .deleteButton:hover {
    background-color: #ff1a1a;
  }
  
  .editButton:hover {
    background-color: #3e8e41;
  }
  
  .confirmDeletePopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
  }
/* Buttons unter dem Textinhalt in einer Zeile */
.eventCardActions {
    display: flex;
    flex-direction: row; /* Buttons nebeneinander */
    justify-content: flex-start; /* Links ausrichten */
    margin-top: 10px; /* Abstand zwischen dem Ort und den Buttons */
  }
  
  .editButton, .deleteButton {
    background-color: blue; /* Grün für Bearbeiten */
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px; /* Platz zwischen den Buttons */
  }
  
  .deleteButton {
    background-color: #ff4d4d; /* Rot für Löschen */
  }
  
  .deleteButton:hover {
    background-color: #ff1a1a;
  }
  
  .editButton:hover {
    background-color: #12084b;
  }
  
  
  /* Anpassung für Mobilgeräte */
  @media (max-width: 768px) {
    .calendarPage {
      padding: 10px;
    }
  
    .eventCard {
      width: 85%;
      max-width: 400px;
      padding: 10px;
      margin: 10px;
      flex-direction: column;
      align-items: center; /* Zentriere die Inhalte auf Mobilgeräten */
    }
  
    .eventCardDate {
      font-size: 28px;
      margin-bottom: 10px;
    }
  
    .eventCardContent {
      text-align: center; /* Zentriere den Text auf Mobilgeräten */
    }
  
    .eventCardImage img {
      max-width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
  
    .confirmButton, .cancelButton {
      width: 100%;
      padding: 12px;
    }
  }
  